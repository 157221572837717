<template>
  <div id="error-container">
    <v-alert
      prominent
      type="error"
      class="text-center"
    >
      There was a problem loading the component
    </v-alert>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
#error-container {
  width:90%;
  margin-left:auto;
  margin-right:auto;
  margin-top: 5px;
}
</style>
